import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Typography, Fade, Paper,
} from '@material-ui/core';
import {
  FolderOpen, RotateLeft,
  ArrowUpward,
  Check,
  BuildOutlined,
  Contacts,
  Group,
  DoneAll,

} from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import {
  SERVICE, ACCESS, ACCESS_REQUEST, MAINTENANCE, SERVICE_REQUEST,
} from '../redux/action-constants';
import {
  IN_PROGRESS, ACCEPTED, CLOSED, OPEN, COMPLETED,
} from '../util/constants';
import StatusButton from '../containers/StatusButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  box: {
    width: '27%',
    // borderStyle: 'solid',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '95%',
  },
  label: {
  },
}));

function Dashboard(props) {
  const {
    services,
    accesses,
    notifications,
    fetchStatuses,
    filterService,
    filterAccess,
    resetFilters,
    fetchNotifications,
    resetNotificationFilters,
  } = props;
  const classes = useStyles();
  const history = useHistory();

  useEffect(() => {
    fetchStatuses();
    resetNotificationFilters();
  }, [fetchStatuses]);

  const navigateToAccess = filter => {
    resetFilters(ACCESS);
    filterAccess(filter);
    history.push('/access-requests');
  };

  const navigateToService = filter => {
    resetFilters(SERVICE);
    filterService(filter);
    history.push('/service-requests');
  };

  const navigateToNotifications = filter => {
    fetchNotifications(filter);
    history.push('/notifications');
  };

  return (
    <Fade in>
      <div
        className={ classes.container }
        data-cy='home-screen'
      >
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='access-request-label'
            >
              Access Requests
            </Typography>
          </div>
          <StatusButton
            value={ accesses.inProgress }
            label='IN PROGRESS'
            Icon={ RotateLeft }
            onClick={ () => navigateToAccess(IN_PROGRESS) }
            data-cy='access-request-in-progress'
          />
          <StatusButton
            value={ accesses.accepted }
            label='ACCEPTED'
            Icon={ ArrowUpward }
            onClick={ () => navigateToAccess(ACCEPTED) }
            data-cy='access-request-accepted'
          />
          <StatusButton
            label='CLOSED'
            Icon={ Check }
            onClick={ () => navigateToAccess(CLOSED) }
            data-cy='access-request-closed'
          />
        </Paper>
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='service-request-label'
            >
              Service Requests
            </Typography>
          </div>
          <StatusButton
            value={ services.open }
            label='OPEN'
            Icon={ FolderOpen }
            onClick={ () => navigateToService(OPEN) }
            data-cy='service-request-open'
          />
          <StatusButton
            value={ services.accepted }
            label='ACCEPTED'
            Icon={ RotateLeft }
            onClick={ () => navigateToService(ACCEPTED) }
            data-cy='service-request-accepted'
          />
          <StatusButton
            label='COMPLETED'
            Icon={ DoneAll }
            onClick={ () => navigateToService(COMPLETED) }
            data-cy='service-request-completed'
          />
        </Paper>
        <Paper
          elevation={ 3 }
          className={ classes.box }
        >
          <div
            className={ classes.label }
          >
            <Typography
              variant='h6'
              data-cy='notification-label'
            >
              Notifications
            </Typography>
          </div>
          <StatusButton
            data-cy='notification-maintenance'
            label='Maintenance'
            Icon={ BuildOutlined }
            onClick={ () => navigateToNotifications(MAINTENANCE) }
          />
          <StatusButton
            data-cy='notification-access'
            label='Access Requests'
            Icon={ Group }
            onClick={ () => navigateToNotifications(ACCESS_REQUEST) }
          />
          <StatusButton
            data-cy='notification-service'
            label='Service Requests'
            Icon={ Contacts }
            onClick={ () => navigateToNotifications(SERVICE_REQUEST) }
            isLoading={ false }
          />
        </Paper>
      </div>
    </Fade>
  );
}

Dashboard.propTypes = {
  services: PropTypes.objectOf(PropTypes.number).isRequired,
  accesses: PropTypes.objectOf(PropTypes.number).isRequired,
  notifications: PropTypes.objectOf(PropTypes.number).isRequired,
  fetchStatuses: PropTypes.func.isRequired,
  fetchNotifications: PropTypes.func.isRequired,
  filterService: PropTypes.func.isRequired,
  filterAccess: PropTypes.func.isRequired,
  resetFilters: PropTypes.func.isRequired,
  resetNotificationFilters: PropTypes.func.isRequired,
};

export default Dashboard;
