import { API } from 'aws-amplify';
import { SET_STATUSES_LOADING, SET_STATUSES } from '../action-constants';
import { validateAuth } from '../../util/util';
import { setAuthState, enqueueSnackbar } from './app';
import { SIGN_OUT, API_NAME } from '../../util/constants';

// Fetches Service, access requests,
// TODO: Maintenance
// eslint-disable-next-line import/prefer-default-export
export const fetchStatuses = () => dispatch => {
  dispatch({ type: SET_STATUSES_LOADING, payload: true });
  validateAuth(session => {
    API.get(API_NAME, '/statuses',
      {
        headers: {
          Authorization: session.getIdToken().getJwtToken(),
        },
        // 15 seconds
        timeout: 15 * 1000,
      })
      .then(data => {
        dispatch({ type: SET_STATUSES, payload: data });
        dispatch({ type: SET_STATUSES_LOADING, payload: false });
      })
      .catch(error => {
        dispatch(enqueueSnackbar({
          message: `Failed fetching data. ${error}`,
          options: {
            key: new Date().getTime() + Math.random(),
            variant: 'error',
          },
        }));
      });
  }, error => { console.error({ error }); setAuthState(SIGN_OUT)(dispatch); });
};
