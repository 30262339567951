import {
  SET_AUTH_STATE, ENQUEUE_SNACKBAR, CLOSE_SNACKBAR, REMOVE_SNACKBAR, DASHBOARD_SELECTED,
} from '../action-constants';
import { SIGN_OUT } from '../../util/constants';

export const enqueueSnackbar = notification => {
  const key = notification.options && notification.options.key;

  return {
    type: ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
    },
  };
};

export const closeSnackbar = key => ({
  type: CLOSE_SNACKBAR,
  dismissAll: !key, // dismiss all if no key has been defined
  key,
});

export const removeSnackbar = key => ({
  type: REMOVE_SNACKBAR,
  key,
});

export const notificationSuccess = message => enqueueSnackbar({
  message,
  options: {
    key: new Date().getTime() + Math.random(),
    variant: 'success',
  },
});

export const notificationError = error => enqueueSnackbar({
  message: `Failed fetching data. ${error}`,
  options: {
    key: new Date().getTime() + Math.random(),
    variant: 'error',
  },
});

export const setDashboard = dashboard => dispatch => {
  dispatch({
    type: DASHBOARD_SELECTED, dashboard,
  });
};

export const setAuthState = authState => dispatch => {

  if (authState === SIGN_OUT) dispatch(setDashboard(null));

  dispatch({
    type: SET_AUTH_STATE, authState,
  });
};

export const signOut = dispatch => setAuthState(SIGN_OUT)(dispatch);
