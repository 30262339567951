import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Badge, Typography, LinearProgress, makeStyles, Fade,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  button: {
    minHeight: '25%',
    minWidth: '80%',
    position: 'relative',
  },
  largeButton: {
    minHeight: '50%',
    minWidth: '80%',
    position: 'relative',
  },
  buttonContentContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
  },
  buttonLabel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    minHeight: 40,
    minWidth: 40,
  },
  biggerIcon: {
    minWidth: 80,
    minHeight: 80,
  },
  progressContainer: {
    width: '100%',
    height: '100%',
    position: 'absolute',
  },
  progress: {
    width: '100%',
    height: '100%',
    opacity: 0.25,
  },
  comingSoon: {
    color: theme.palette.text.secondary,
    textTransform: 'none',
    fontStyle: 'italic',
    fontSize: '12pt',
  },
}));

function DashboardButton({
  value,
  label,
  Icon,
  onClick,
  largeIcon,
  isLoading,
  'data-cy': dataCy,
  comingSoon,
}) {
  const classes = useStyles();
  return (
    <Button
      variant='outlined'
      className={ largeIcon ? classes.largeButton : classes.button }
      classes={ {
        label: classes.buttonContentContainer,
      } }
      disabled={ comingSoon }
      onClick={ onClick }
      data-cy={ dataCy }
    >
      <div className={ classes.buttonLabel }>
        <Badge
          anchorOrigin={ {
            horizontal: 'right',
            vertical: 'top',
          } }
          invisible={ value === 0 || value === null }
          max={ 999 }
          badgeContent={ value }
          color='primary'
          data-cy='badge'
        >
          <Icon className={ largeIcon ? classes.biggerIcon : classes.icon } />
        </Badge>
        <Typography
          data-cy='label'
        >
          {label}
        </Typography>
      </div>
      { comingSoon && (
        <div className={ classes.comingSoon } data-cy='coming-soon'>
          Coming soon...
        </div>
      )}
      <Fade
        in={ isLoading }
        timeout={ {
          exit: 500,
        } }
      >
        <div className={ classes.progressContainer }>
          <LinearProgress className={ classes.progress } />
        </div>
      </Fade>
    </Button>
  );
}

DashboardButton.defaultProps = {
  value: null,
  comingSoon: false,
};

DashboardButton.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string.isRequired,
  Icon: PropTypes.objectOf(PropTypes.any).isRequired,
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  'data-cy': PropTypes.string.isRequired,
  largeIcon: PropTypes.bool,
  comingSoon: PropTypes.bool,
};

export default DashboardButton;
