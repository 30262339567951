import React, { useState } from 'react';
import './App.css';
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from '@material-ui/core/';
import { PersistGate } from 'redux-persist/integration/react';

import { Provider } from 'react-redux';
import Cookies from 'universal-cookie';
import { purple } from '@material-ui/core/colors';
import Amplify from 'aws-amplify';
import configureStore from './redux/store';
import awsConfig from './aws-exports';
import AppContent from './layout/AppContent';

const { store, persistor } = configureStore();

Amplify.configure(awsConfig);

const darkTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    type: 'dark',
    primary: {
      main: purple[300],
    },
    secondary: {
      main: '#03DAC6',
    },
    error: {
      main: '#CF6679',
    },
  },
}));

const lightTheme = responsiveFontSizes(createMuiTheme({
  palette: {
    primary: {
      main: '#003FBC',
    },
  },
}));

function App() {
  const cookies = new Cookies();
  const [darkMode, setDarkMode] = useState(cookies.get('darkMode')
    ? Boolean(JSON.parse(cookies.get('darkMode'))) : false);

  const toggleTheme = () => {
    const cookieDark = cookies.get('darkMode');
    const toggleDark = cookieDark !== undefined ? !JSON.parse(cookieDark) : true;
    cookies.set('darkMode', toggleDark, { path: '/' });
    setDarkMode(toggleDark);
  };

  return (
    <Provider store={ store }>
      <PersistGate loading={ null } persistor={ persistor }>
        <ThemeProvider theme={ darkMode ? darkTheme : lightTheme }>
          <AppContent toggleTheme={ toggleTheme } darkMode={ darkMode } />
        </ThemeProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
