import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  makeStyles, Fade, Paper,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  SecurityOutlined,
  PlaylistAddCheckOutlined,
  PermContactCalendar,
} from '@material-ui/icons';
import { setDashboard } from '../redux/actions/app';
import { dashboardSelector } from '../redux/selectors';
import {
  OPERATIONAL, SECURITY, ACCOUNT_MANAGEMENT,
} from '../util/constants';
import StatusButton from '../containers/StatusButton';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
  },
  box: {
    width: '40%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
    height: '40%',
    margin: '5%',
  },
}));

function PromptPage(props) {
  const {
    selectedDashboard, setDashboard,
  } = props;
  const classes = useStyles();

  const setSecurity = useCallback(() => {
    if (selectedDashboard !== SECURITY) setDashboard(SECURITY);
  }, [selectedDashboard, setDashboard]);

  const setAccountManagement = useCallback(() => {
    if (selectedDashboard !== ACCOUNT_MANAGEMENT) setDashboard(ACCOUNT_MANAGEMENT);
  }, [selectedDashboard, setDashboard]);

  const setOperational = useCallback(() => {
    if (selectedDashboard !== OPERATIONAL) setDashboard(OPERATIONAL);
  }, [selectedDashboard, setDashboard]);

  return (
    <Fade in>
      <div
        className={ classes.container }
        data-cy='prompt-screen'
      >
        <Paper
          elevation={ 2 }
          className={ classes.box }
        >
          <StatusButton
            label='SECURITY DASHBOARD'
            Icon={ SecurityOutlined }
            largeIcon
            onClick={ () => setSecurity() }
            data-cy='security-dashboard-portal'
          />
        </Paper>
        <Paper
          elevation={ 2 }
          className={ classes.box }
        >
          <StatusButton
            label='OPERATIONS DASHBOARD'
            Icon={ PlaylistAddCheckOutlined }
            largeIcon
            onClick={ () => setOperational() }
            data-cy='operations-dashboard-portal'
          />
        </Paper>
        <Paper
          elevation={ 2 }
          className={ classes.box }
        >
          <StatusButton
            label='ACCOUNT MANAGEMENT DASHBOARD'
            Icon={ PermContactCalendar }
            largeIcon
            onClick={ () => setAccountManagement() }
            data-cy='account-management-portal'
          />
        </Paper>
      </div>
    </Fade>
  );
}

PromptPage.defaultProps = {
  selectedDashboard: null,
};
PromptPage.propTypes = {
  setDashboard: PropTypes.func.isRequired,
  selectedDashboard: PropTypes.string,
};

const mapStateToProps = state => (
  { selectedDashboard: dashboardSelector(state) }
);

const mapDispatchToProps = dispatch => (
  { setDashboard: event => dispatch(setDashboard(event)) }
);

export default connect(mapStateToProps, mapDispatchToProps)(PromptPage);
