import React, { useState, useEffect, Suspense } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Auth } from 'aws-amplify';
import {
  Brightness4Outlined, Brightness7,
} from '@material-ui/icons';
import {
  makeStyles,
  Tooltip,
  Fab,
  Tabs,
  Tab,
} from '@material-ui/core/';
import {
  Route,
  Redirect,
  useLocation,
  useHistory,
} from 'react-router-dom';
import PromptPage from './PromptPage';
import { dashboardSelector } from '../redux/selectors';
import Loading from '../components/shared/Loading';
import ErrorBoundary from '../components/shared/ErrorBoundary';
import Error from '../components/shared/Error';
import { OPERATIONS_TABS, ACCOUNT_MANAGEMENT_TABS, SECURITY_TABS } from './Tabs';
import DataCentresLogo from './DataCentresLogo-nobg.png';
import DataCentresLogoWhite from './DataCentresLogo-white.png';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    flexShrink: 0,
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: theme.palette.background.paper,
  },
  tabs: {
    width: '150px',
    height: '100%',
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  tabLink: {
    width: '100%',
    display: 'flex',
    textDecoration: 'none',
  },
  tab: {
    top: 105,
    flex: 1,
    color: theme.palette.text.primary,
    padding: '15px 20px',
  },
  fab: {
    position: 'absolute',
    bottom: '7%',
    left: '50%',
    marginLeft: -25,
    zIndex: 1,
  },
  logo: {
    width: 100,
    position: 'absolute',
    top: '1.5%',
    left: '50%',
    marginLeft: -45,
  },
  routeContainer: {
    flex: 1,
    height: '100%',
  },
}));

const a11yProps = index => ({
  'aria-controls': `vertical-tabpanel-${index}`,
});

const renderTabComponent = component => {
  const Component = component;
  return <Component />;
};

const TabLayout = ({ darkMode, toggleTheme, setSignOut }) => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const signOut = () => Auth.signOut(() => setSignOut()).catch(err => console.error(err));

  const selectedDashboard = useSelector(dashboardSelector);

  const getTabs = () => {
    if (selectedDashboard === 'Operational') {
      return OPERATIONS_TABS;
    }
    if (selectedDashboard === 'Security') {
      return SECURITY_TABS;
    }
    return ACCOUNT_MANAGEMENT_TABS;
  };

  // Set the tab index when the route changes.
  useEffect(() => {
    const index = getTabs().findIndex(tab => tab.pathname === location.pathname);
    setTabIndex(index === -1 ? 0 : index);
  }, [location.pathname]);

  const renderDashboard = () => (!getTabs().some(tab => location.pathname === tab.pathname) ? (
    <Redirect to={ getTabs()[0].pathname } />
  ) : (
    <>
      <div className={ classes.root }>
        <Tabs
          orientation='vertical'
          variant='scrollable'
          value={ tabIndex }
          aria-label='Navigation Tabs'
          className={ classes.tabs }
        >
          {getTabs().map((tab, index) => (
            !tab.hide && (
              <Tab
                key={ tab.pathname }
                disabled={ !tab.component }
                label={ tab.label }
                className={ classes.tab }
                onClick={ () => history.push(tab.pathname) }
                { ...a11yProps(index) }
              />
            )
          ))}
          <Tab label='Log out' { ...a11yProps(getTabs().length) } className={ classes.tab } onClick={ signOut } />
          {darkMode ? <img className={ classes.logo } src={ DataCentresLogoWhite } alt = "Datacom DataCentres Logo" /> : <img className={ classes.logo } src={ DataCentresLogo } alt = "Datacom DataCentres Logo" /> }
          <Tooltip title={ darkMode ? 'Use Light Theme' : 'Use Dark Theme' }>
            <Fab
              onClick={ toggleTheme }
              className={ classes.fab }
              color='primary'
            >
              {!darkMode && <Brightness4Outlined />}
              {darkMode && <Brightness7 className={ classes.white } />}
            </Fab>
          </Tooltip>
        </Tabs>
        <div className={ classes.routeContainer }>
          {getTabs().map(tab => !tab.hide && (
            <Route key={ tab.pathname } path={ tab.pathname }>
              <ErrorBoundary fallback={ <Error /> }>
                <Suspense fallback={ <Loading /> }>
                  {tab.component ? renderTabComponent(tab.component) : <Redirect to={ getTabs()[0].pathname } />}
                </Suspense>
              </ErrorBoundary>
            </Route>
          ))}
        </div>
      </div>
    </>
  )
  );

  return !selectedDashboard
    ? (
      <PromptPage />
    )
    : renderDashboard();
};

TabLayout.defaultProps = {
  darkMode: false,
};

TabLayout.propTypes = {
  darkMode: PropTypes.bool,
  toggleTheme: PropTypes.func.isRequired,
  setSignOut: PropTypes.func.isRequired,
};

export default TabLayout;
