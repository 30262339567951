export const OPEN = 'open';
export const IN_PROGRESS = 'in_progress';
export const ACCEPTED = 'accepted';
export const COMPLETED = 'completed';
export const DECLINED = 'declined';
export const CANCELLED = 'cancelled';
export const CLOSED = 'closed';

export const CHECKED_IN = 'checked_in';
export const CHECKED_OUT = 'checked_out';
export const UNDER_INVESTIGATION = 'under_investigation';

export const SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [ACCEPTED]: 'Accepted',
  [COMPLETED]: 'Completed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const NEW_SERVICE_REQUEST_STATUSES = {
  [OPEN]: 'Open',
  [ACCEPTED]: 'Accepted',
  [COMPLETED]: 'Completed',
};

export const ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
  [CLOSED]: 'Closed',
  [DECLINED]: 'Declined',
  [CANCELLED]: 'Cancelled',
};

export const NEW_ACCESS_REQUEST_STATUSES = {
  [IN_PROGRESS]: 'In Progress',
  [ACCEPTED]: 'Accepted',
};

export const MAINTENANCE_STATUSES = {
  completed: 'Completed',
  scheduled: 'Scheduled',
};

// access reason options
export const ACCESS_REASON_STATUSES = {
  rack_access: 'Rack Access',
  device_install: 'Device Install',
  device_removal: 'Device Removal',
  tour: 'Tour',
  build_room: 'Build Room',
  induction: 'Induction',
  contractor_visit: 'Contractor Visit',
  other: 'Other',
};

export const CONTACT_TYPES = {
  approver: 'Approver',
  billing: 'Billing',
  maintenance: 'Maintenance',
  reporting: 'Reporting',
  site_access: 'Site Access',
  tape_management: 'Tape Management',
};

export const NOTIFICATION_FILTER_TYPES = {
  access_request: 'Access Request',
  service_request: 'Service Request',
  maintenance: 'Maintenance',
  manual: 'Manual',
};

export const NOTIFICATION_TYPES = {
  datacentre: 'Data Centre',
  customer: 'Customer',
  data_floor: 'Data Floor',
  power_module: 'Power Module',
  rack: 'Rack',
  asset: 'Asset',
  location: 'Location',
};

export const DOCUMENT_TYPE = {
  monthly_report: 'Monthly Report',
  newsletter: 'Newsletter',
  policy: 'Policy',
};

export const KEY_REQUEST_STATUSES = {
  [CHECKED_IN]: 'Checked In',
  [CHECKED_OUT]: 'Checked Out',
  [UNDER_INVESTIGATION]: 'Under Investigation',
};

export const KEY_REQUEST_CO_STATUSES = {
  [CHECKED_IN]: 'Checked In',
  [UNDER_INVESTIGATION]: 'Under Investigation',
};

export const NEW_KEY_REQUEST_STATUSES = {
  [CHECKED_OUT]: 'Checked Out',
};

export const CUSTOMER_DB_TEST = "https://dc-custdb-sys.datacomdev.co.nz/Administration";
export const CUSTOMER_DB_UAT = "https://dc-custdb-uat.datacomdev.co.nz/Administration";
export const CUSTOMER_DB_PRODUCTION = "https://customerdatabase.datacentres.datacom.net.nz/Administration";

export const DATE_FORMAT = 'll';
export const DATE_TIME_FORMAT = 'llll';
export const DATE_TIME_DRAWER_FORMAT = 'DD/MM/YYYY h:mm a z';

export const NOTES_MAX_LENGTH = 4000;
export const NOTES_MAX_CHARACTER = 500;

export const ALL_OR_NO_SELECTION = 0;


export const ESCORT_REQUIRED_STATUS = [
  'yes', 'no',
];

export const ACCESS = 'ACCESS';
export const ADDITIONAL = 'ADDITIONAL';
export const ADDITIONAL_REQUESTER = 'ADDITIONAL_REQUESTER';

export const SERVICE = 'SERVICE';

export const SIGN_OUT = 'signOut';
export const SIGN_IN = 'signIn';

export const SECURITY = 'Security';
export const OPERATIONAL = 'Operational';
export const ACCOUNT_MANAGEMENT = 'Account_Management';

export const KEY = 'KEY';

export const API_NAME = process.env.REACT_APP_AWS_API_NAME;

export const maxCharacterLength = length => `Max length of ${length} characters reached`;
export const fieldRequired = field => `${field} is required`;
export const invalidBeforeCurrentDateTime = (fieldName, dateTime) => (
  `${fieldName} date time cannot before or be the same as ${dateTime}`
);

export const TO_DATE_AFTER_FROM_DATE = 'End date time must be after Scheduled the date time';
export const FROM_DATE_BEFORE_TO_DATE = 'Schedule date time must be before the End date time';
export const STATUS_INVALID = 'Request status is invalid';
export const REQUESTER_EMAIL_INVALID = 'Invalid email format';
export const RACKS_REQUIRED = 'Select at least one rack';
export const ACCESS_ZONES_REQUIRED = 'Select at least one access zone';
export const ADDITIONAL_CONTACT_NOT_REQUIRED = 'Request has non access list contacts';
export const ACCESS_REASON_REQUIRED = 'Select one or more access reasons';
export const ACCESS_REASON_OTHER = 'Other Access Reason chosen - Manually validate';
export const KEY_REQUIRED = 'Please select at least one key from Available Keys - Key(s) being Checked Out or Under Investigation by another ticket will not be available.';
export const REQUESTER_REQUIRED = 'Select or Add at least one requester';
export const CHANGE_REQUEST_NUMBER_REQUIRED = 'Change Request Number Required';
export const RACKS_INVALID = 'One or more of your racks has an invalid date';
export const CONTACT_REQUIRED = 'Must have at least one Access contact or Additional contact';
export const ACCESS_CONTACT_REQUIRED = 'Must have at least one Access contact';
export const KEYS_REQUIRED = 'Must have at least one Key';
export const REQUESTERS_REQUIRED = 'Must have at least one Requester';
export const ESCORT_NAME_REQUIRED = 'Must have Escort Name if Escort Required is Yes for Additional contact';
export const ACCESS_CONTACT_SITE_ACCESS = 'Contact(s) do not have the attribute site access';
export const ACCESS_CONTACT_INDUCTION = 'Contact(s) are missing valid induction';
export const COMMENTS_REQUIRED = 'Requester Email is required';
export const EQUINIX_NUMBER_REQUIRED = 'Equinix Access Number is required';
export const AIRTRUNK_NUMBER_REQUIRED = 'AirTrunk Access Number is required';

export const ACCESS_REQUEST_FORM_PERMISSIONS = {
  in_progress: {
    permittedStatuses: [ACCEPTED, CANCELLED, DECLINED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  accepted: {
    permittedStatuses: [CANCELLED, DECLINED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  declined: {
    permittedStatuses: [IN_PROGRESS, ACCEPTED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons', // Access Reason Options
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'airtrunk_access_number',
      'equinix_access_number',
    ],
  },
  cancelled: {
    permittedStatuses: [IN_PROGRESS, ACCEPTED, CLOSED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons',
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'airtrunk_access_number',
      'no_racks_override',
      'site_visitors_notified',
      'equinix_access_number',
    ],
  },
  closed: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'customer',
      'datacentre',
      'requester',
      'reason_for_access',
      'access_reasons',
      'from_date', // Scheduled Date/Time
      'to_date', // End Date/Time
      'racks',
      'change_request_number',
      'access_list_site_visitors', // Access Contacts
      'additional_site_visitors', // Additional Contacts
      'no_racks_override',
      'site_visitors_notified',
    ],
  },
};

export const KEY_REQUEST_FORM_PERMISSIONS = {
  checked_in: {
    permittedStatuses: [UNDER_INVESTIGATION],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
    ],
  },
  under_investigation: {
    permittedStatuses: [CHECKED_IN],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
    ],
  },
  checked_out: {
    permittedStatuses: [CHECKED_IN, UNDER_INVESTIGATION],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'datacentre_key_lockers',
    ],
  },
};

export const SERVICE_REQUEST_FORM_PERMISSIONS = {
  [OPEN]: {
    permittedStatuses: [ACCEPTED, CANCELLED, DECLINED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  [ACCEPTED]: {
    permittedStatuses: [CANCELLED, DECLINED, COMPLETED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
    ],
  },
  [DECLINED]: {
    permittedStatuses: [ACCEPTED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
    ],
  },
  [CANCELLED]: {
    permittedStatuses: [ACCEPTED],
    unchangeableFields: [
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
    ],
  },
  [COMPLETED]: {
    permittedStatuses: [],
    unchangeableFields: [
      'status',
      'customer',
      'datacentre',
      'requester',
      'title',
      'description',
      'devices',
      'from_date', // Scheduled Date/Time
      'racks',
      'change_request_number',
      'no_racks_override',
    ],
  },
};
