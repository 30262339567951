import {
  ACCESS, SERVICE, SET_STATUSES_LOADING, SET_STATUSES, NOTIFICATION,
} from '../action-constants';

const initialState = {
  isLoading: false,
  access: {
    inProgress: null,
    accepted: null,
    close: null,
  },
  service: {
    open: null,
    inProgress: null,
  },
  notification: {
    maintenance: null,
    access: null,
    service: null,
  },
};

const setStatusLoading = (state, action) => {
  const { payload } = action;
  return {
    ...state,
    isLoading: payload,
  };
};

const setStatuses = (state, { payload }) => ({
  ...state,
  [ACCESS]: {
    inProgress: payload.access.in_progress,
    accepted: payload.access.accepted,
    closed: payload.access.closed,
  },
  [SERVICE]: {
    open: payload.service.open,
    accepted: payload.service.accepted,
  },
  [NOTIFICATION]: {
    maintenance: payload.notification.maintenance,
    access: payload.notification.access,
    service: payload.notification.service,
  },
});

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_STATUSES_LOADING: {
      return setStatusLoading(state, action);
    }
    case SET_STATUSES: {
      return setStatuses(state, action);
    }
    default:
      return state;
  }
};
