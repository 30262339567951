import axios from 'axios';
import {
  FILTER_REQUESTS,
  CLEAR_FILTER,
  REQUEST_LOADING,
  SET_REQUEST,
  RESET_FILTER,
  RETURN_FILTER,
  ON_SORT,
  APPLY_QUERY_PARAMS_TO_TABLE,
  CLEAR_NOTIFICATION_FILTER,
  FILTER_NOTIFICATION_REQUESTS,
} from '../action-constants';
import { validateAuth } from '../../util/util';

export const changeFilterRequests = (requestType, payload, filter, params) => dispatch => {
  dispatch({
    type: FILTER_REQUESTS, requestType, payload, filter, params,
  });
};

export const clearRequestFilter = (requestType, filter) => dispatch => {
  dispatch({ type: CLEAR_FILTER, requestType, filter });
};

export const changeNotificationFilterRequests = dashBoardNotificationType => dispatch => {
  dispatch({
    type: FILTER_NOTIFICATION_REQUESTS, dashBoardNotificationType,
  });
};

export const clearNotificationRequestFilter = dashboardNotificationType => dispatch => {
  dispatch({ type: CLEAR_NOTIFICATION_FILTER, dashboardNotificationType });
};

export const setRequestLoading = (loading, requestType) => dispatch => (
  dispatch({ type: REQUEST_LOADING, requestType, payload: loading })
);

export const setRequest = (data, requestType) => dispatch => {
  dispatch({ type: SET_REQUEST, payload: data, requestType });
};

export const resetFilters = requestType => dispatch => {
  dispatch({ type: RESET_FILTER, requestType });
};

export const returnFilters = requestType => dispatch => {
  dispatch({ type: RETURN_FILTER, requestType });
};

export const onSort = (requestType, columnId) => dispatch => {
  dispatch({ type: ON_SORT, requestType, columnId });
};

// Handles query param interpretation and applies any applicable sorting and filtering.
export const applyQueryParamsToTable = (requestType, queryParams) => async dispatch => {
  const additionalData = {};

  // Fetch additional data for ID based query params.

  if (queryParams.customerId) {
    additionalData.customerId = await validateAuth(session => axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/customers/${queryParams.customerId}`,
      {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        timeout: 45 * 1000,
      },
    )
      .then(response => ({ name: response.data.name }))
      .catch(() => null));
  }

  if (queryParams.dataCentreId) {
    additionalData.dataCentreId = await validateAuth(session => axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/datacentres/${queryParams.dataCentreId}`,
      {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        timeout: 45 * 1000,
      },
    )
      .then(response => ({ name: response.data.name }))
      .catch(() => null));
  }

  if (queryParams.keyId) {
    additionalData.keyId = await validateAuth(session => axios.get(
      `${process.env.REACT_APP_AWS_API_URL}/datacentre_key_lockers/${queryParams.keyId}`,
      {
        headers: { Authorization: session.getIdToken().getJwtToken() },
        timeout: 45 * 1000,
      },
    )
      .then(response => ({ name: response.data.name }))
      .catch(() => null));
  }

  dispatch({
    type: APPLY_QUERY_PARAMS_TO_TABLE, requestType, queryParams, additionalData,
  });
};
