import { lazy } from 'react';
import Home from '../containers/Home';

const AccessRequestTable = lazy(() => (import('../containers/AccessRequestTable')));
const SecurityAccessRequestTable = lazy(() => (import('../containers/SecurityAccessRequestTable')));
const SecurityKeyRequestTable = lazy(() => (import('../containers/SecurityKeyRequestTable')));
const ServiceRequestTable = lazy(() => (import('../containers/ServiceRequestTable')));
const KeyRequestTable = lazy(() => (import('../containers/KeyRequestTable')));
const NotificationCentre = lazy(() => import('../components/Notification/NotificationCentre'));
const DocumentCentre = lazy(() => import('../components/DocumentCentre/DocumentCentre'));
const PromptPage = lazy(() => import('./PromptPage'));

export const OPERATIONS_TABS = [
  {
    label: 'Home',
    component: Home,
    pathname: '/home',
  },
  {
    label: 'Access Requests',
    component: AccessRequestTable,
    pathname: '/access-requests',
  },
  {
    label: 'Service Requests',
    component: ServiceRequestTable,
    pathname: '/service-requests',
  },
  {
    label: 'Data Centre Keys',
    component: KeyRequestTable,
    pathname: '/key-requests',
  },
  {
    label: 'Notifications',
    component: NotificationCentre,
    pathname: '/notifications',
  },
  {
    label: 'Switch User',
    component: PromptPage,
    pathname: '/prompt-operations',
  },
];

export const ACCOUNT_MANAGEMENT_TABS = [
  {
    label: 'Notifications',
    component: NotificationCentre,
    pathname: '/notifications',
  },
  {
    label: 'Document Centre',
    component: DocumentCentre,
    pathname: '/documents-centre',
  },
  {
    label: 'Switch User',
    component: PromptPage,
    pathname: '/prompt-customer',
  },
];

export const SECURITY_TABS = [
  {
    label: 'Security',
    component: SecurityAccessRequestTable,
    pathname: '/access-requests',
  },
  {
    label: 'Data Centre Keys',
    component: SecurityKeyRequestTable,
    pathname: '/key-requests',
  },
  {
    label: 'Switch User',
    component: PromptPage,
    pathname: '/prompt-security',
  },
];
