import { connect } from 'react-redux';
import {
  accessStatusSelector, serviceStatusSelector, notificationStatusSelector,
} from '../redux/selectors';
import Dashboard from '../components/Dashboard';
import { SERVICE, STATUS, ACCESS } from '../redux/action-constants';
import {
  changeFilterRequests, resetFilters, changeNotificationFilterRequests, clearNotificationRequestFilter,
} from '../redux/actions/requests';
import { fetchStatuses } from '../redux/actions/statuses';

const mapStateToProps = state => ({
  services: serviceStatusSelector(state),
  accesses: accessStatusSelector(state),
  notifications: notificationStatusSelector(state),
});

const mapDispatchToProps = dispatch => ({
  fetchStatuses: () => dispatch(fetchStatuses()),
  fetchNotifications: filter => dispatch(changeNotificationFilterRequests(filter)),
  resetNotificationFilters:
  dashboardNotificationType => (dispatch(clearNotificationRequestFilter(dashboardNotificationType))),
  filterService: filter => (dispatch(changeFilterRequests(SERVICE, filter, STATUS))),
  filterAccess: filter => (dispatch(changeFilterRequests(ACCESS, filter, STATUS))),
  resetFilters: requestType => (dispatch(resetFilters(requestType))),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
