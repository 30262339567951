import {
  SET_AUTH_STATE,
  ENQUEUE_SNACKBAR,
  CLOSE_SNACKBAR,
  REMOVE_SNACKBAR,
  DASHBOARD_SELECTED,
} from '../action-constants';

const initialState = {
  authState: null,
  dashboard: null,
  notifications: [],
};

const setAuthState = (state, action) => {
  const { authState } = action;
  return {
    ...state,
    authState,
  };
};

const setDashboard = (state, action) => {
  const { dashboard } = action;
  return {
    ...state,
    dashboard,
  };
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_AUTH_STATE: {
      return setAuthState(state, action);
    }
    case DASHBOARD_SELECTED: {
      return setDashboard(state, action);
    }
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            key: action.key,
            ...action.notification,
          },
        ],
      };

    case CLOSE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
            ? { ...notification, dismissed: true }
            : { ...notification }
        )),
      };

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          notification => notification.key !== action.key,
        ),
      };
    default:
      return state;
  }
};
